body, .dash-info-des-0{
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: rgba(0,0,0,.87);

}
.checkout-0{
    font-size: 22px !important;
    font-weight: 400 !important;
    color: #2d2c2cde;
}
.checkout-ps-info{ 
    font-size: 13px !important;
    font-weight: 40 !important;
    color: #000;
}
.checkout-0, .checkout-description-0, .checkout-description-1{
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif !important;
    line-height: 1.5 !important;
}
.checkout-description-0{
    font-size: 16px !important;
    font-weight: 200 !important;
    color: #2d2c2cde;
}
.checkout-description-1{
    font-size: 13px !important;
    font-weight: 200 !important;
    color: #343232de;
    text-align: center;
}
.f-w-label{
    font-weight: 500 !important;
}
.f-w-label-checkbox{
    font-weight: 10 !important;
    font-size: 10rem !important;
}
.bg-text-com-wp{
    background-color: #FF7643;
    color: #fff;
}

.body, .header, .footer, .bg-light{
    background-color: #fff !important;
}
.sidebar{
    /* background-color: var(--cui-sidebar-bg, #757579) !important; */
    background-color: var(red, #fff) !important;
}
.header{
    border-bottom: var(--cui-header-border-width, 1px) solid var(--cui-header-border-color, #1152bd);
}

.login-input:focus {
    border-color: white !important;
    box-shadow: none !important;
}
.profile-img, .profile-img:hover, .profile-img:focus{
    background-color: #fafafa;
    box-shadow: none !important;
    border: solid 10px #fff;
}

.my-actions { margin: 0 2em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }

.right-gap {
  margin-right: auto;
}

















@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
.home__container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__container > * {
  margin-bottom: 10px;
}
.home__header {
  margin-bottom: 30px;
}
.username__input {
  padding: 10px;
  width: 50%;
}
.home__cta {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #607EAA;
  color: #F9F5EB;
  outline: none;
  border: none;
  border-radius: 5px;
}
.chat {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.chat__sidebar {
  height: 100%;
  background-color: #F9F5EB;
  flex: 0.2;
  padding: 20px;
  border-right: 1px solid #fdfdfd;
}
.chat__main {
  height: 100%;
  flex: 0.8;
}
.chat__header {
  margin: 30px 0 20px 0;
}
.chat__users > * {
  margin-bottom: 10px;
  color: #607EAA;
  font-size: 14px;
}
.online__users > * {
  margin-bottom: 10px;
  color: rgb(238, 102, 102);
  font-style: italic;
}
.chat__mainHeader {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #F9F5EB;
}
.leaveChat__btn {
  padding: 10px;
  width: 150px;
  border: none;
  outline: none;
  background-color: #D1512D;
  cursor: pointer;
  color: #EAE3D2;
}
.contact_container{
  width: 35%;
  height: 80vh;
  background-color: #fff;
  padding: 20px;
  overflow-y: scroll;
  float: left;
}

.message__container {
  width: 60%;
  height: 80vh;
  background-color: #fff;
  padding: 20px;
  overflow-y: scroll;
  float: right;
}

.message__container > * {
  margin-bottom: 10px;
}
.chat__footer {
  padding: 10px;
  background-color: #F9F5EB;
  height: 10vh;
}
.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message {
  width: 80%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  outline: none;
  padding: 15px;
}
.sendBtn {
  width: 150px;
  background-color: #080908;
  padding: 10px;
  border: none;
  outline: none;
  color: #EAE3D2;
  cursor: pointer;
}
.sendBtn:hover {
  background-color: #4e524e;
}
.message__recipient {
background-color: #f5ccc2;
width: 300px;
padding: 10px;
border-radius: 10px;
font-size: 15px;
}
.message__sender{
background-color: #9f9f97;
max-width: 300px;
padding: 10px;
border-radius: 10px;
margin-left: auto;
font-size: 15px;
}
.message__chats > p {
  font-size: 13px;
}
.sender__name {
  text-align: right;
}
.message__status {
  width: 60%;
  position: fixed;
  bottom: 50px;
  font-size: 13px;
  font-style: italic;
}


.contact__recipient {
  background-color: #fff;
  color: #000;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  }
  .nav-next{
    float: right;
    padding: 0px 10px 0px 10px;
    background: #927272;
    color: #fff;
    margin: 2px;
  }
.nav-stop{
  float: left;
  padding: 0px 10px 0px 10px;
  background: #370808;
  color: #fff;
  margin: 2px;
}

.wp-cursor-pointer{
  cursor: pointer;
}

/* App.css */
pre {
  background-color: #f5f5f5;
  color: #D1512D;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
